import { Global } from '@emotion/react'
import * as React from 'react'

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
      font-family: 'Gotham Black';
      font-weight: 500;
      font-style: normal;
        font-display: swap;
        src: url('./fonts/Gotham-Black.woff2') format('woff2'), url('./fonts/Gotham-Black.woff') format('woff');
      }
      /* latin */
       @font-face {
      font-family: 'Gotham Book';
      font-weight: normal;
      font-style: normal;
        font-display: swap;
        src: url('./fonts/Gotham-Book.woff2') format('woff2'), url('./fonts/Gotham-Book.woff') format('woff');
      }
      /* latin */
      @font-face {
      font-family: 'Gotham Extra';
      font-weight: 200;
      font-style: normal;
        font-display: swap;
        src: url('./fonts/Gotham-ExtraLight.woff2') format('woff2'), url('./fonts/Gotham-ExtraLight.woff') format('woff');
      }
     /* latin */
      @font-face {
      font-family: 'Gotham Bold';
      font-weight: bold;
      font-style: normal;
        font-display: swap;
        src: url('./fonts/Gotham-Bold.woff2') format('woff2'), url('./Gotham-Bold.woff') format('woff');
      }
         /* latin */
      @font-face {
      font-family: 'Gotham Medium';
      font-weight: normal;
      font-style: normal;
        font-display: swap;
        src: url('./fonts/Gotham-Medium.woff2') format('woff2'), url('./Gotham-Medium.woff') format('woff');
      }
      `}
  />
)

export default Fonts