import { extendTheme } from "@chakra-ui/react"
import "@fontsource/open-sans";

export const theme = extendTheme({
 fonts: {
    heading: "Gotham, sans-serif",
    body: "Gotham Extra, sans-serif",
    button: "Gotham Medium, sans-serif",
  },
  styles: {
    global: {

      ul: {
        marginLeft: "24px !important"
      }, 
      ol: {
        marginLeft: "24px !important"
      }

    }
  },
  colors: {
    base: {
      "50": "#fff", "100": "#000"
    },
    blue: {
      "50":"#EBEEFA","100":"#C6CFF0","200":"#A2B0E7","300":"#7E91DD","400":"#5972D4","500":"#3554CA","600":"#2A43A2","700":"#203279","800":"#152151","900":"#0B1128"
    },
    gray: {
      "50":"#F1F1F4","100":"#D7D8E0","200":"#BDBFCC","300":"#A3A7B8","400":"#898EA4","500":"#6F7590","600":"#595D73","700":"#434656","800":"#2D2F39","900":"#16171D"
    },
    cyan: {
      "50":"#ECF8F6","100":"#CAEDE6","200":"#A8E1D6","300":"#85D5C5","400":"#63CAB5","500":"#41BEA5","600":"#349884","700":"#277263","800":"#1A4C42","900":"#0D2621"
    },
    teal: {
      "50":"#ECF9F0","100":"#C9EED4","200":"#A6E2B8","300":"#84D79C","400":"#61CC80","500":"#3EC164","600":"#329A50","700":"#25743C","800":"#194D28","900":"#0C2714"
    },
    yellow: {
      "50":"#F9F6EC","100":"#EEE6C9","200":"#E2D6A6","300":"#D7C684","400":"#CCB661","500":"#C1A63E","600":"#9A8532","700":"#746425","800":"#4D4219","900":"#27210C"
    },
    orange: {
      "50":"#FAEFEB","100":"#F0D3C6","200":"#E7B6A2","300":"#DD9A7E","400":"#D47D59","500":"#CA6135","600":"#A24D2A","700":"#793A20","800":"#512715","900":"#28130B"
    },
    red: {
      "50":"#FAEBEE","100":"#F0C6CF","200":"#E7A2B0","300":"#DD7E91","400":"#D35972","500":"#CA3553","600":"#A22B42","700":"#792032","800":"#511521","900":"#280B11"
    },
    purple: {
      "50":"#EFEBFA","100":"#D3C6F0","200":"#B6A2E7","300":"#9A7DDE","400":"#7D58D4","500":"#6134CB","600":"#4D2AA2","700":"#3A1F7A","800":"#271551","900":"#130A29"
    },
    pink: {
      "50":"#FAEBF5","100":"#F0C6E4","200":"#E7A2D2","300":"#DD7EC0","400":"#D459AF","500":"#CA359D","600":"#A22A7E","700":"#79205E","800":"#51153F","900":"#280B1F"
    }
  },
   components: {
    Button: {
      baseStyle:{
        fontFamily: "Gotham Medium",
        fontWeight: "300",
        color: "gray.700",
        _focus: {
             boxShadow: " !important",
           }
       },
       variants: {
         outline: (props) => ({
           borderColor: "gray.200",
           color: "gray.700",
           _hover: {
             bg: "gray.50"
           }
         }),
         solid: (props) => ({
           bg: "blue.500",
           color: "base.50",
           _hover: {
             bg: "blue.600",
             border: "nonen !important",
           }, 
           
         }), 
         ghost: (props) => ({
           _hover: {
            bg: "gray.50",
          }
         }),
         solidOutline: {
           color: "base.50",
           borderColor: "base.50",
           border: "1px solid",
           _hover: {
             bg: "base.50",
             color: "blue.500"
           }
         }, 
         ghost2: {
            backgroundColor: "transparent",
            color: "base.50",
            fontSize: "12px",
            height: "24px",
            padding: "8px",
            _hover: {
              bg: "base.50",
              color: "#000"
              }
          }
      }
     },
     Link: {
       baseStyle: {
         textDecoration: "none !important",
         color: "blue.500"
       }
     }, 
     Text: {
       baseStyle: {
         fontWeight: "600"
       }
     },
  },
  sizes: {
    container: {
       md: '840px',
     }
   }
})